'use strict';

$('.slider').slick({
    "slidesToScroll": 4,
    "slidesToShow": 4,
    "infinite": true,
    "arrows": true,
    "prevArrow": '<button class="slide-arrow prev-arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
    "nextArrow": '<button class="slide-arrow next-arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
    "dots": true,
    "rows": 1,
    "responsive": [
        {
            "breakpoint": 1023,
            "settings": {
                "slidesToScroll": 2,
                "slidesToShow": 2
            }
        },
        {
            "breakpoint": 769,
            "settings": {
                "slidesToScroll": 1,
                "slidesToShow": 1,
                "arrows": false,
                "centerMode": true,
                "centerPadding": '75px'
            }
        }
    ]
});